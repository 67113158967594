import React, { useState, useEffect, createRef } from "react";
import { useHistory } from "react-router-dom";
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormGroup,
  Checkbox,
} from "@material-ui/core";

import api from "../../services/api";

import idQuestionCreate from "../../utils/idQuestionCreate";
import { dateFormat } from "../../utils/InputMasks";
import data from "./data.json";
import {
  Header,
  Form,
  Error,
  QuestionCard,
  ContainerLoading,
  MainContainer,
} from "./styles";

const FormSO = ({ match }) => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [formId, setFormId] = useState("");
  const [formTitle, setFormTitle] = useState("");
  const [formSubTitle, setFormSubTitle] = useState("");

  const [formConfig, setFormConfig] = useState([]);
  const [formResponses, setFormResponses] = useState([]);
  const [inputError, setInputError] = useState("");
  const [refs, setRefs] = useState({});

  function createDefaultResponses(formSetup) {
    const responseSetup = [];

    for (let i = 0; i < formSetup.length; i++) {
      if (formSetup[i].type === "checkbox") {
        const arrayPossibleAnswers = formSetup[i].possibleAnswers;

        const arrayPABoolean = arrayPossibleAnswers.map((answer) => {
          return { [answer]: false, value: answer };
        });

        responseSetup.push({ [formSetup[i].idQuestion]: arrayPABoolean });
      } else if (formSetup[i].type === "date") {
        responseSetup.push({ [formSetup[i].idQuestion]: "" });
      } else {
        responseSetup.push({ [formSetup[i].idQuestion]: "Sem Resposta" });
      }
    }

    return responseSetup;
  }

  useEffect(() => {
    const formQuestionsSetup = [];
    const formSetup = data.questions;
    setFormId(data.formId);
    setFormTitle(data.title);
    setFormSubTitle(data.subtitle);

    const arrayQuestionsId = Object.keys(formSetup);

    for (let i = 0; i < arrayQuestionsId.length; i++) {
      formQuestionsSetup.push({
        ...formSetup[arrayQuestionsId[i]],
        index: i,
      });
    }

    setFormConfig(formQuestionsSetup);

    const refs = formQuestionsSetup.reduce((acc, question) => {
      acc[question.idQuestion] = createRef();
      return acc;
    }, {});

    setRefs(refs);

    const responseSetup = createDefaultResponses(formQuestionsSetup);

    setFormResponses(responseSetup);
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();

    for (let i = 0; i < formResponses.length; i++) {
      const [key] = Object.keys(formResponses[i]);
      const { required, type } = formConfig.find(
        (question) => question.idQuestion === key
      );

      if (
        (!formResponses[i][key] ||
          formResponses[i][key] === "Sem Resposta" ||
          formResponses[i][key] === "Outro") &&
        required &&
        refs[key].current
      ) {
        setInputError(`${key}-Necessário preencher este campo!`);
        refs[key].current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        return;
      }

      if (
        type === "date" &&
        formResponses[i][key].length !== 10 &&
        required &&
        refs[key].current
      ) {
        setInputError(`${key}-Necessário preencher este campo!`);
        refs[key].current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        return;
      }

      if (Array.isArray(formResponses[i][key])) {
        const checkedAnswers = formResponses[i][key].filter((answer) => {
          const [key] = Object.keys(answer);
          if (answer[key]) {
            return true;
          } else {
            return false;
          }
        });

        if (!checkedAnswers.length && required && refs[key].current) {
          setInputError(`${key}-Necessário preencher estes campos!`);
          refs[key].current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
          return;
        }
      }
    }

    const finalFormResponses = {
      userId: match.params.userid,
      formId: '-MHmfl08XTpYFElbDhYm',
      createdAt: new Date().toISOString(),
      answers: {},
    };

    for (let i = 0; i < formResponses.length; i++) {
      const questionIdNumber = idQuestionCreate(i);

      const [uid] = Object.keys(formResponses[i]);

      finalFormResponses.answers[questionIdNumber] = formResponses[i][uid];
    }

    setInputError("");

    try {
      setLoadingSubmit(true);
      const formAnswers = await api.post("/submit-form", finalFormResponses);
      setLoading(false);
      if (!formAnswers.error) {
        history.push("/submitted-form/submit", {
          responses: finalFormResponses,
          questions: formConfig,
        });
      } else {
        const error =
          typeof formAnswers.error === "string"
            ? formAnswers.error
            : JSON.stringify(formAnswers.error);
        throw new Error(error);
      }
    } catch (err) {
      setLoadingSubmit(false);
      const error =
        err.response?.data?.error ||
        err.response?.data?.message ||
        err.message ||
        "Ocorreu um erro ao salvar no banco de dados, tente novamente.";
      alert(error);
    }
  }

  function handleChange(e) {
    const propTitle = e.target.title;
    const propName = e.target.name;
    let changedValue = "";

    if (propTitle === "Data") changedValue = dateFormat(e.target.value);
    else changedValue = e.target.value;
    console.log(changedValue);
    const refs = formConfig.reduce((acc, question) => {
      acc[question.idQuestion] = createRef();
      return acc;
    }, {});

    setRefs(refs);

    const formResponsesUpdated = formResponses.map((response) => {
      if (response.hasOwnProperty(propName)) {
        if (Array.isArray(response[propName])) {
          const answerOther = response[propName].find((answer) =>
            answer.hasOwnProperty(changedValue)
          );

          let updatedAnswers = [];
          if (!answerOther) {
            updatedAnswers = response[propName].map((answer) => {
              return answer.hasOwnProperty("Outro")
                ? { Outro: true, value: changedValue }
                : answer;
            });
          } else {
            updatedAnswers = response[propName].map((answer) => {
              return answer.hasOwnProperty(changedValue)
                ? { [changedValue]: e.target.checked, value: changedValue }
                : answer;
            });
          }

          const checkedAnswers = updatedAnswers.filter((answer) => {
            const [key] = Object.keys(answer);
            if (answer[key]) {
              return true;
            } else {
              return false;
            }
          });

          const { required } = formConfig.find(
            (question) => question.idQuestion === propName
          );

          if (!checkedAnswers.length && required) {
            setInputError(`${propName}-Necessário preencher estes campos!`);
          } else {
            setInputError("");
          }

          return { [propName]: updatedAnswers };
        } else {
          const { required } = formConfig.find(
            (question) => question.idQuestion === propName
          );

          if (changedValue && changedValue !== "Outro") {
            setInputError("");
          } else if (required) {
            setInputError(`${propName}-Necessário preencher este campo!`);
          }
          return { [propName]: changedValue };
        }
      } else {
        return response;
      }
    });

    setFormResponses(formResponsesUpdated);
  }

  function checkError(questionId) {
    if (!inputError) {
      return false;
    } else {
      const [questionIdError] = inputError.split("-");
      if (questionIdError === questionId) {
        return true;
      } else {
        return false;
      }
    }
  }

  function checkAnswer(questionId) {
    if (formResponses.length && formConfig.length) {
      const question = formConfig.find((question) => {
        if (question.idQuestion === questionId) {
          return true;
        } else {
          return false;
        }
      });
      if (question.type === "radio-button") {
        const answersDiffFromOther = question.possibleAnswers.filter(
          (answer) => answer !== "Outro"
        );

        const answerObject = formResponses.find((answer) => {
          if (answer.hasOwnProperty(questionId)) {
            return true;
          } else {
            return false;
          }
        });

        const value = answerObject[questionId];

        if (!answersDiffFromOther.includes(value) && value !== "Sem Resposta") {
          return true;
        }
        return false;
      } else if (question.type === "checkbox") {
        const arrayAnswers = formResponses.find((response) => {
          if (response.hasOwnProperty(questionId)) {
            return true;
          }
          return false;
        });

        const answerOther = arrayAnswers[questionId].find((answer) =>
          answer.hasOwnProperty("Outro")
        );

        let isOtherChecked = false;

        if (answerOther) {
          isOtherChecked = answerOther["Outro"];
        }

        if (isOtherChecked) {
          return true;
        }
        return false;
      }
      return "";
    }
  }

  function checkDependantAnswer(dependantQuestionId, dependantAnswer) {
    if (formResponses.length && formConfig.length) {
      const answerObject = formResponses.find((answer) => {
        if (answer.hasOwnProperty(dependantQuestionId)) {
          return true;
        }
        return false;
      });

      const value = answerObject[dependantQuestionId];
      if (dependantAnswer.includes(value)) {
        return true;
      }
      return false;
    }
    return false;
  }

  return (
    <>
      <MainContainer>
        <Header>
          <h1>{formTitle}</h1>
          <p>{formSubTitle}</p>
        </Header>
        {loading && (
          <ContainerLoading>
            <h1>Carregando formulário...</h1>
          </ContainerLoading>
        )}
        <Form onSubmit={handleSubmit}>
          <ul>
            {!loading &&
              formConfig.map((question) => {
                if (question.type === "text") {
                  if (question.dependant) {
                    const dependantQuestionId = question.dependant.idQuestion;
                    const dependantAnswer = question.dependant.answer;
                    return (
                      <>
                        {checkDependantAnswer(
                          dependantQuestionId,
                          dependantAnswer
                        ) && (
                          <>
                            <QuestionCard
                              ref={refs[question.idQuestion]}
                              key={question.idQuestion}
                              hasError={inputError}
                              questionId={question.idQuestion}
                            >
                              <p>{question.title}</p>
                              {question.required && (
                                <span className="required-text">
                                  **obrigatória
                                </span>
                              )}
                              {question.subinfo && (
                                <span className="subinfo">
                                  {question.subinfo}
                                </span>
                              )}
                              <input
                                name={question.idQuestion}
                                required={!!question.required}
                                type={question.type}
                                placeholder="Sua Resposta"
                                onChange={handleChange}
                              />
                            </QuestionCard>
                            {checkError(question.idQuestion) && (
                              <Error>{inputError.split("-")[1]}</Error>
                            )}
                          </>
                        )}
                      </>
                    );
                  }
                  return (
                    <>
                      <QuestionCard
                        ref={refs[question.idQuestion]}
                        key={question.idQuestion}
                        hasError={inputError}
                        questionId={question.idQuestion}
                      >
                        <p>{question.title}</p>
                        {question.required && (
                          <span className="required-text">**obrigatória</span>
                        )}
                        {question.subinfo && (
                          <span className="subinfo">{question.subinfo}</span>
                        )}
                        <input
                          name={question.idQuestion}
                          required={!!question.required}
                          type={question.type}
                          placeholder="Sua Resposta"
                          onChange={handleChange}
                        />
                      </QuestionCard>
                      {checkError(question.idQuestion) && (
                        <Error>{inputError.split("-")[1]}</Error>
                      )}
                    </>
                  );
                } else if (question.type === "date") {
                  if (question.dependant) {
                    const dependantQuestionId = question.dependant.idQuestion;
                    const dependantAnswer = question.dependant.answer;
                    return (
                      <>
                        {checkDependantAnswer(
                          dependantQuestionId,
                          dependantAnswer
                        ) && (
                          <>
                            <QuestionCard
                              ref={refs[question.idQuestion]}
                              key={question.idQuestion}
                              hasError={inputError}
                              questionId={question.idQuestion}
                            >
                              <p>{question.title}</p>
                              {question.required && (
                                <span className="required-text">
                                  **obrigatória
                                </span>
                              )}
                              {question.subinfo && (
                                <span className="subinfo">
                                  {question.subinfo}
                                </span>
                              )}
                              <input
                                title="Data"
                                name={question.idQuestion}
                                type="text"
                                placeholder="dd/mm/aaaa"
                                value={
                                  formResponses[question.index][
                                    question.idQuestion
                                  ]
                                }
                                onChange={handleChange}
                                required={!!question.required}
                              />
                            </QuestionCard>
                            {checkError(question.idQuestion) && (
                              <Error>{inputError.split("-")[1]}</Error>
                            )}
                          </>
                        )}
                      </>
                    );
                  }
                  return (
                    <>
                      <QuestionCard
                        ref={refs[question.idQuestion]}
                        key={question.idQuestion}
                        hasError={inputError}
                        questionId={question.idQuestion}
                      >
                        <p>{question.title}</p>
                        {question.required && (
                          <span className="required-text">**obrigatória</span>
                        )}
                        {question.subinfo && (
                          <span className="subinfo">{question.subinfo}</span>
                        )}
                        <input
                          title="Data"
                          name={question.idQuestion}
                          type="text"
                          placeholder="dd/mm/aaaa"
                          value={
                            formResponses[question.index][question.idQuestion]
                          }
                          onChange={handleChange}
                          required={!!question.required}
                        />
                      </QuestionCard>
                      {checkError(question.idQuestion) && (
                        <Error>{inputError.split("-")[1]}</Error>
                      )}
                    </>
                  );
                } else if (question.type === "radio-button") {
                  if (question.dependant) {
                    const dependantQuestionId = question.dependant.idQuestion;
                    const dependantAnswer = question.dependant.answer;
                    return (
                      <>
                        {checkDependantAnswer(
                          dependantQuestionId,
                          dependantAnswer
                        ) && (
                          <>
                            <QuestionCard
                              ref={refs[question.idQuestion]}
                              key={question.idQuestion}
                              hasError={inputError}
                              questionId={question.idQuestion}
                            >
                              <p>{question.title}</p>
                              {question.required && (
                                <span className="required-text">
                                  **obrigatória
                                </span>
                              )}
                              {question.subinfo && (
                                <span className="subinfo">
                                  {question.subinfo}
                                </span>
                              )}
                              <FormControl component="fieldset" required>
                                <RadioGroup
                                  name={question.idQuestion}
                                  value={formResponses[question.idQuestion]}
                                  onChange={handleChange}
                                >
                                  {question.possibleAnswers.map((answer) => (
                                    <FormControlLabel
                                      key={answer}
                                      value={answer}
                                      control={<Radio />}
                                      label={
                                        answer === "Outro" ? "Sim" : answer
                                      }
                                    />
                                  ))}
                                </RadioGroup>
                              </FormControl>
                              {checkAnswer(question.idQuestion) && (
                                <input
                                  name={question.idQuestion}
                                  required={!!question.required}
                                  type="text"
                                  placeholder="Qual?"
                                  onChange={handleChange}
                                />
                              )}
                            </QuestionCard>
                            {checkError(question.idQuestion) && (
                              <Error>{inputError.split("-")[1]}</Error>
                            )}
                          </>
                        )}
                      </>
                    );
                  }
                  return (
                    <>
                      <QuestionCard
                        ref={refs[question.idQuestion]}
                        key={question.idQuestion}
                        hasError={inputError}
                        questionId={question.idQuestion}
                      >
                        <p>{question.title}</p>
                        {question.required && (
                          <span className="required-text">**obrigatória</span>
                        )}
                        {question.subinfo && (
                          <span className="subinfo">{question.subinfo}</span>
                        )}
                        <FormControl component="fieldset" required>
                          <RadioGroup
                            name={question.idQuestion}
                            value={formResponses[question.idQuestion]}
                            onChange={handleChange}
                          >
                            {question.possibleAnswers.map((answer) => (
                              <FormControlLabel
                                key={answer}
                                value={answer}
                                control={<Radio />}
                                label={answer === "Outro" ? "Sim" : answer}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                        {checkAnswer(question.idQuestion) && (
                          <input
                            name={question.idQuestion}
                            required={!!question.required}
                            type="text"
                            placeholder="Qual?"
                            onChange={handleChange}
                          />
                        )}
                      </QuestionCard>
                      {checkError(question.idQuestion) && (
                        <Error>{inputError.split("-")[1]}</Error>
                      )}
                    </>
                  );
                } else if (question.type === "checkbox") {
                  return (
                    <>
                      <QuestionCard
                        ref={refs[question.idQuestion]}
                        key={question.idQuestion}
                        hasError={inputError}
                        questionId={question.idQuestion}
                      >
                        <p>{question.title}</p>
                        {question.required && (
                          <span className="required-text">**obrigatória</span>
                        )}
                        {question.subinfo && (
                          <span className="subinfo">{question.subinfo}</span>
                        )}
                        <FormGroup>
                          {question.possibleAnswers.map((answer) => (
                            <FormControlLabel
                              key={answer}
                              control={
                                <Checkbox
                                  onChange={handleChange}
                                  name={question.idQuestion}
                                  value={answer}
                                />
                              }
                              label={answer}
                            />
                          ))}
                        </FormGroup>
                        {checkAnswer(question.idQuestion) && (
                          <input
                            name={question.idQuestion}
                            required={!!question.required}
                            type="text"
                            placeholder="Qual?"
                            onChange={handleChange}
                          />
                        )}
                      </QuestionCard>
                      {checkError(question.idQuestion) && (
                        <Error>{inputError.split("-")[1]}</Error>
                      )}
                    </>
                  );
                }
                return true;
              })}
          </ul>
          <button type="submit" disabled={loadingSubmit}>
            {loadingSubmit ? "Enviando..." : "Enviar"}
          </button>
        </Form>
      </MainContainer>
    </>
  );
};

export default FormSO;
