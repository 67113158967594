import React, { useState, useEffect } from "react";
import { FiArrowRight } from "react-icons/fi";
import axios from "axios";
import { useHistory } from "react-router-dom";

// import imgLogoAmigosPoli from "../../assets/amigos_da_poli_logo.png";
// import imgLogoPoliUsp from "../../assets/poli_usp_logo.jpg";
// import imgLogoMedfusion from "../../assets/medfusion_logo.png";
// import imgLogoHealthinn from "../../assets/healthinn_logo.png";
import imgRecommendations from "../../assets/message-recomendations.jpg";

import api from "../../services/api";

import {
  ResponseCard,
  Title,
  SubmitMessage,
  Button,
  MainContainer,
  // PartnersContainer,
  NoContent,
} from "./styles";

const Normal = <p>
  <strong>Que bom!</strong> No momento, você <strong>não</strong> apresenta sintomas sugestivos de uma infecção por COVID-19!
Seguem algumas sugestões do que você pode continuar fazendo como prevenção:
</p>;

const Ambulatorial = <div>
  <p>Vimos que não apresenta sinais de urgência. Mas gostaríamos de conversar com você para uma melhor avaliação do seu caso.<br/>
  Ligue agora para <strong>(74) 99138-5190</strong> e faça uma avaliação pelo nosso serviço de teleatendimento.</p>

  <p>O nosso serviço é totalmente gratuito!<br/>
  Conte conosco! :)</p>
</div>;

const Emergencial = <p>
  <p>
  Vimos que não está se sentindo tão bem, não é?<br/>
  Em caso de falta de ar, orientamos que procure um serviço médico presencial <strong>imediatamente!</strong> Se preferir, ligue agora para <strong>(74) 99138-5190</strong> e faça uma avaliação pelo nosso serviço de teleatendimento.
  </p>

  <p>
  O nosso serviço é totalmente gratuito!<br/>
  Conte conosco! :)
  </p>
</p>;

const scoreTextMessages = {
  Normal,
  Ambulatorial,
  Emergencial,
};

const FormSubmitted = ({ location, match }) => {
  const history = useHistory();

  const [responses, setResponses] = useState({});
  const [questions, setQuestions] = useState([]);

  const [showAnswers, setShowAnswers] = useState(false);
  const [covidForm, setCovidForm] = useState(false);
  const [formScore, setFormScore] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const answerId = match.params.answerId;

    if (location.state && answerId === "submit") {
      setResponses(Object.values(location.state.responses.answers));
      setQuestions(location.state.questions);

      if (location.state.questions[0].title === "Qual é seu CPF?") {
        setCovidForm(true);
      }

      if (location.state.mode === 'score') {
        setFormScore(location.state.score);
      }
    }

    if (answerId && answerId !== "submit") {
      // api request com answersId
      setShowAnswers(true);
      setLoading(true);

      const answersRequest = api.post("/form-answers", {
        ids: [answerId],
      });

      const questionsRequest = api.post("/form-questions", {
        answersIds: [answerId],
      });

      axios
        .all([answersRequest, questionsRequest])
        .then(
          axios.spread((answersResponse, questionsResponse) => {
            const rawAnswers = answersResponse.data[0].answers;
            const answersKeys = Object.keys(rawAnswers);
            const formattedAnswers = answersKeys.map((key) => rawAnswers[key]);

            const rawQuestions = questionsResponse.data[0].questions;
            const questionKeys = Object.keys(rawQuestions);
            const formattedQuestions = questionKeys.map(
              (key) => rawQuestions[key]
            );

            setResponses(formattedAnswers);
            setQuestions(formattedQuestions);
          })
        )
        .catch((errors) => {
          console.log(errors);
          alert("Erro ao buscar as respostas! Tente novamente.");
        })
        .finally(() => setLoading(false));
    }
  }, [location.state, match.params]);

  useEffect(() => {
    console.log(history);
    if (history.action === "PUSH") {
      window.location.reload();
    }
  }, [history]);

  return (
    <>
      {/* {covidForm && !hasScore && (
        <PartnersContainer>
          <div>
            <img src={imgLogoPoliUsp} alt="Poli USP" />
            <img src={imgLogoAmigosPoli} alt="Amigos da Poli" />
            <img src={imgLogoMedfusion} alt="Medfusion" />
            <img src={imgLogoHealthinn} alt="Health.Inn" />
          </div>

          <div className="contact">
            <a
              href="https://wa.me/5511933383995"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i
                className="fab fa-whatsapp fa-lg"
                style={{ marginRight: 5 }}
              ></i>
              (11) 93338-3995
            </a>
          </div>
        </PartnersContainer>
      )} */}

      <MainContainer>
        <Title>Obrigado pela sua resposta!</Title>
        {covidForm && !formScore && (
          <SubmitMessage>
            <strong>
            Agora é só aguardar que a equipe de saúde da sua empresa entrará em contato sobre próximos passos!
            </strong>

            {/* <p>
              No aplicativo, você terá sua análise e atendimento imediato para
              sua situação.
            </p>

            <a href="https://rebrand.ly/hinn1">
              Ir para loja de aplicativos
              <FiArrowRight />
            </a> */}

            <span>
            * Caso sinta desconforto respiratório ou piora do quadro, orientamos procurar atendimento médico presencial imediatamente.
            </span>
          </SubmitMessage>
        )}

        {covidForm && formScore && (
          <SubmitMessage bahia>
            {scoreTextMessages[formScore]}

            {formScore === 'Normal' && (
              <img className="recommendations" src={imgRecommendations} alt="Recomendações para COVID-19"/>
            )}
            {/* <a href="https://rebrand.ly/hinn1">
              Ir para loja de aplicativos
              <FiArrowRight />
            </a> */}

            {/* <span>
              *Lembramos que, nesse momento de pandemia, os serviços de
              atendimento médico e monitoramento online estão sendo
              racionalizados para os casos de necessidade. Mas não se preocupe!
              Você tem liberdade para conversar com a gente quando quiser.
            </span> */}
          </SubmitMessage>
        )}

        {!loading && questions.length ? (
          <Button>
            <button type="button" onClick={() => setShowAnswers(!showAnswers)}>
              {showAnswers
                ? "Esconder minhas respostas"
                : "Ver minhas respostas"}
            </button>
          </Button>
        ) : null}

        {!loading &&
          showAnswers &&
          questions.map((question, index) => (
            <ResponseCard key={question.idQuestion}>
              <p>
                {index + 1}. {question.title}{" "}
              </p>
              <span>
                {Array.isArray(responses[index])
                  ? responses[index].map((response) => {
                      const [key] = Object.keys(response);

                      if (response[key]) {
                        return `${response.value}, `;
                      }
                      return "";
                    })
                  : responses[index]}
              </span>
            </ResponseCard>
          ))}

        {!loading && !questions.length && (
          <NoContent>
            <strong>Nenhuma resposta encontrada :(</strong>
          </NoContent>
        )}

        {loading && (
          <NoContent>
            <strong>Carregando...</strong>
          </NoContent>
        )}
      </MainContainer>
    </>
  );
};

export default FormSubmitted;
