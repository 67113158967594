import React from 'react';
import { Switch, Route } from 'react-router-dom';

import FormSO from '../pages/FormSO';
import FormCovid from '../pages/FormCovid';
import FormCovidBahia from '../pages/FormCovidBahia';
import FormSM from '../pages/FormSM';
import FormSubmitted from '../pages/FormSubmitted';
import FormSOEditable from '../pages/FormSOEditable';

const Routes = () => (
  <Switch>
    <Route path="/form/:userid" component={FormSO} />
    <Route path="/form-covid" component={FormCovid} />
    <Route path="/ba-form-covid" component={FormCovidBahia} />
    <Route path="/form-saude-mental" component={FormSM} />
    <Route path="/submitted-form/:answerId" component={FormSubmitted} />
    <Route path="/edit-mode-cfbc8bd1ca004df7d1e4be1aef8a704b" component={FormSOEditable} />
  </Switch>
);

export default Routes;
