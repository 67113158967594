import styled from "styled-components";

export const MainContainer = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding: 40px 20px;
`;

export const PartnersContainer = styled.div`
  display: flex;
  height: 15vw;
  margin: 0 auto;
  max-height: 100px;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

  strong {
    color: #a8a8b3;
  }

  div {
    display: flex;
    width: 40%;
    flex: 2;
    height: 100%;
    align-items: center;
    justify-content: center;

    img {
      width: 15%;
      height: auto;
      margin: 0 10px;

      object-fit: contain;
    }
  }

  div.contact {
    justify-content: right;
    flex: 1;

    a {
      display: flex;
      text-decoration: none;
      color: #03c04a;
      border: 2px solid #03c04a;
      border-radius: 30px;
      padding: 15px 10px;
      font-weight: bold;
      align-items: center;
      margin: 0 auto;
      transition: opacity 0.5s;
      transition: background-color 0.5s;

      &:hover {
        opacity: 0.8;
        color: #fff;
        background: #03c04a;
      }
    }
  }

  @media (max-width: 750px) {
    div {
      img {
        width: 20%;
      }
    }

    div.contact {
      font-size: 14px;
    }
  }

  @media (max-width: 530px) {
    div {
      padding-left: 10px;
    }

    div.contact {
      font-size: 10px;
      padding-left: 0;

      a {
        padding: 10px 5px;
        font-weight: bold;
        margin: 40px auto;
        transition: opacity 0.5s;
        transition: background-color 0.5s;
      }
    }
  }

  @media (max-width: 440px) {
    div {
      flex: 2.5;
      padding-left: 5px;
    }

    div.contact {
      flex: 1;
      font-size: 10px;
      padding-left: 0;

      a {
        padding: 8px 5px;
        font-weight: bold;
        margin: 0 auto;
        transition: opacity 0.5s;
        transition: background-color 0.5s;
      }
    }
  }

  @media (max-width: 390px) {
    flex-direction: column;
    height: 30vw;
    max-height: 200px;

    div {
      flex: 1;
      margin: 0;

      img {
        width: 45%;
      }
    }

    div.contact {
      font-size: 12px;
    }
  }
`;

export const Header = styled.header`
  text-align: center;

  h1 {
    font-size: 46px;
    color: #023d68;
    margin-bottom: 50px;
  }

  @media (max-width: 700px) {
    h1 {
      font-size: 32px;
    }
  }

  @media (max-width: 600px) {
    h1 {
      font-size: 24px;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ul {
    list-style: none;
    display: flex;
    width: 70%;
    margin: 10px auto 20px;
    flex-direction: column;

    > button {
      margin-top: 20px;
      align-self: center;
      align-items: center;
      width: 30%;
    }
  }

  button {
    background: #023d68;
    color: #fff;
    font-weight: bold;
    border: 0;
    padding: 10px;
    width: 30%;
    border-radius: 5px;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.8;
    }
  }

  @media (max-width: 520px) {
    ul {
      width: 90%;

      button {
        width: 50%;
      }
    }

    button {
      width: 50%;
    }
  }
`;

export const QuestionCard = styled.li`
  min-height: 200px;
  background: #fff;
  display: flex;
  flex: 1;
  padding: 20px 30px;
  border: 2px solid #fff;
  border-radius: 5px;
  flex-direction: column;

  border-color: ${(props) => {
    const inputError = props.hasError;
    const questionId = props.questionId;
    if (!inputError) {
      return "#fff";
    } else {
      const [questionIdError] = inputError.split("-");
      if (questionIdError === questionId) {
        return "#EA2448";
      }
    }
  }};

  & + li {
    margin-top: 20px;
  }

  p {
    font-size: 24px;
    color: #3d3d4d;
  }

  span {
    font-size: 18px;
    color: #a8a8b3;
    margin: 0;
  }

  span.subinfo {
    margin-bottom: 10px;
  }

  span.required-text {
    display: inline-flex;
    margin-bottom: 20px;
    font-size: 14px;
    color: #ea2448;
  }

  input[type="text"],
  input[type="cpf"],
  input[type="tel"],
  input[type="number"] {
    margin-top: auto;
    margin-bottom: 10px;
    border: 0;
    border-bottom: 1px solid #949494;
    border-radius: 0;
    font-size: 18px;
    padding: 5px 5px 2px 5px;
    color: #3d3d4d;
    width: 50%;

    &::placeholder {
      color: #a8a8b3;
    }
  }

  .date {
    margin-top: auto;
    margin-bottom: 10px;
    border: 0;
    border-radius: 0;
    font-size: 18px;
    padding: 5px 5px 2px 5px;
    color: #3d3d4d;
    width: 50%;
  }

  @media (max-width: 700px) {
    min-height: 120px;

    label svg {
      width: 20px;
    }

    p {
      font-size: 16px;
    }

    span {
      font-size: 12px;
    }

    span.required-text {
      font-size: 12px;
    }

    input {
      font-size: 10px;

      &::placeholder {
        font-size: 10px;
      }
    }

    input[type="text"],
    input[type="cpf"],
    input[type="tel"] {
      width: 70%;
      font-size: 12px;
    }

    .date {
      width: 70%;
    }
  }

  @media (max-width: 400px) {
    label svg {
      width: 15px;
    }

    p {
      font-size: 16px;
    }

    span {
      font-size: 12px;
    }

    span.required-text {
      font-size: 10px;
    }

    input {
      font-size: 12px;

      &::placeholder {
        font-size: 12px;
      }
    }

    input[type="text"],
    input[type="cpf"],
    input[type="tel"] {
      font-size: 10px;
    }
  }
`;

export const Error = styled.span`
  display: block;
  color: #ea2448;
  margin-top: 8px;

  @media (max-width: 700px) {
    margin-top: 5px;
    font-size: 12px;
  }

  @media (max-width: 375px) {
    margin-top: 2px;
    font-size: 10px;
  }
`;

export const ContainerLoading = styled.div`
  display: flex;
  margin: 100px auto;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  color: #023d68;

  @media (max-width: 700px) {
    h1 {
      font-size: 32px;
    }
  }

  @media (max-width: 400px) {
    h1 {
      font-size: 24px;
      text-align: center;
    }
  }
`;

export const WelcomeMessage = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 40px auto 20px;
  width: 70%;
  background: #fff;
  padding: 40px;
  border-radius: 5px;

  strong {
    font-size: 24px;
    color: #023d68;
    margin-bottom: 20px;
  }

  p {
    font-size: 18px;
    color: #3d3d4d;
    font-weight: bold;
  }

  a {
    text-decoration: none;
    background: #023d68;
    color: #fff;
    font-weight: bold;
    border: 2px solid #023d68;
    margin: 20px auto;
    padding: 10px;
    width: 30%;
    border-radius: 5px;
    transition: background-color 0.4s;

    &:hover {
      background: #fff;
      color: #023d68;
    }
  }

  @media (max-width: 740px) {
    strong {
      font-size: 18px;
      color: #023d68;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
    }
  }

  @media (max-width: 500px) {
    width: 90%;

    a {
      width: 50%;
    }
  }
`;

export const TermsOfUseMessage = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 20px auto;
  width: 70%;
  background: #fff;
  padding: 40px 20px;
  border-radius: 5px;

  strong {
    font-size: 24px;
    color: #023d68;

    & + strong {
      margin-top: 20px;
    }
  }

  a {
    display: flex;
    text-decoration: none;
    color: #3d3d4d;
    font-weight: bold;
    align-items: center;
    margin: 40px auto;
    transition: opacity 0.5s;
    transition: transform 0.5s;

    &:hover {
      opacity: 0.8;
      transform: translateX(3px);
    }
  }

  button {
    font-size: 14px;
    background: #023d68;
    color: #fff;
    font-weight: bold;
    border: 2px solid #023d68;
    margin: 0 auto;
    padding: 10px;
    width: 30%;
    border-radius: 5px;
    transition: background-color 0.4s;

    &:hover {
      background: #fff;
      color: #27b5bf;
      border-color: #27b5bf;
    }
  }

  span {
    font-size: 12px;
    font-weight: bold;
    margin-top: 30px;
  }

  @media (max-width: 740px) {
    strong {
      font-size: 18px;
      color: #023d68;
      margin-bottom: 10px;
    }

    span {
      font-size: 12px;
    }
  }

  @media (max-width: 500px) {
    width: 90%;

    button {
      width: 50%;
    }
  }
`;

export const CovidInformationBalloon = styled.button`
  text-decoration: none;
  text-color: #3d3d4d;
  width: 50%;
  height: 50px;
  background: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  border: 0;
  border-radius: 40px;

  @media (max-width: 530px) {
      width: 90%;
  }

  nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 10px 0;

    p {
      display: flex;
      align-items: center;
      justify-content: space-between;

      svg {
        margin-right: 5px;
      }
    }
  }
`;
