import React from 'react';

import { ContainerButtons } from './styles';

import { FiTrash, FiPlus, FiEdit } from 'react-icons/fi';

const EditButtons = ({ onClickAdd, onClickDelete, onClickUpdate }) => {
  return (
    <ContainerButtons>
      <button type="button" className="add" onClick={onClickAdd}>
        <FiPlus size={25} />
        <span>Adicionar</span>
      </button>
      <button type="button" className="delete" onClick={onClickDelete}>
        <FiTrash size={25} />
        <span>Deletar</span>
      </button>
      <button type="button" className="update" onClick={onClickUpdate}>
        <FiEdit size={25} />
        <span>Editar</span>
      </button>
    </ContainerButtons>
  );
}

export default EditButtons;
