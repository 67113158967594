import styled from 'styled-components';

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 70px;
  margin-left: 10px;
  align-items: center;
  height: 200px;

  button {
    position: relative;

    width: 40px;
    height: 40px;
    background: transparent;
    padding: 0;
    border-radius: 50%;
    border: 2px solid #000000;

    span {
      color: #fff;
      margin-left: 10px;
      border-radius: 4px;
      opacity: 0;
      transition: opacity 0.4s;
      visibility: hidden;

      position: absolute;
      bottom: 20%;
      left: 100%;

      &::before {
        content: '';
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;

        border-right: 6px solid blue;
        left: -6%;
        position: absolute;
      }
    }

    &:hover span {
      opacity: 1;
      visibility: visible;
    }
  }

  button.add {
    border-color: #32CD32;
    color: #32CD32;
    transition: background-color 0.5s;

    span {
      background: #32CD32;
      padding: 5px;

      &::before {
        border-right-color: #32CD32;
      }
    }

    &:hover {
      color: #fff;
      border-color: #fff;
      background: #32CD32;
    }
  }

  button.delete {
    border-color: #c53030;
    color: #c53030;
    transition: background-color 0.5s;

    span {
      background: #c53030;
      padding: 5px;

      &::before {
        border-right-color: #c53030;
      }
    }

    &:hover {
      color: #fff;
      border-color: #fff;
      background: #c53030;
    }
  }

  button.update {
    border-color: #7159EB;
    color: #7159EB;
    transition: background-color 0.5s;

    span {
      background: #7159EB;
      padding: 5px;

      &::before {
        border-right-color: #7159EB;
      }
    }

    &:hover {
      color: #fff;
      border-color: #fff;
      background: #7159eb;
    }
  }

  @media (max-width: 700px) {
    height: 150px;
    margin-left: 0;

    button {
      width: 30px;
      height: 30px;

      svg {
        width: 20px;
      }
    }
  }
`;
